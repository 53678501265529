@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

/* { } */

*,
html,
body {
  padding: 0px;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 550;
  font-style: normal;
  font-size: 19px;
}

:root {
  --main: #2667FF;
  --main1: #d7b147;
  --black: #04080F;
}

body .bg-main {
  background: var(--main);
}

body .text-main {
  color: var(--main);
}

body a {
  text-decoration: none;
}

body ol,
body li,
body ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

body .wrapper {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
}

body .btn {
  border-radius: 4px;
  padding: 10px 25px;
  font-weight: 550;
  min-width: 150px;
}

body .btn.bg-main.text-light:hover {
  color: var(--main) !important;
  background: #fff !important;
}


body .btn.bg-white.text-main:hover {
  color: #ffff !important;
  background: var(--main) !important;
}

body .btn.btn-outline-light:hover {
  border-color: var(--main) !important;
  color: var(--main) !important;
}

/* navbar */


body .navbar-hight {
  width: 100%;
  min-height: 20px;
  position: fixed;
  top: 0pc;
  left: 0pc;
  z-index:1000;
  background: var(--black);
  border-bottom:1px solid rgb(48, 48, 48);
}

body .navbar-hight .nav-items {
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
}

body .navbar-hight .logo {
  width: 200px;
  height: 60px;
}


body .navbar-hight .items {
  display: flex;
  align-items: center;
}

body .navbar-hight .items li {
  margin: 0px 10px;
  position: relative;
}

body .navbar-hight .items li a {
  color: #ffff;
  transition: all 1s ease-in-out;
  position: relative;
}

body .navbar-hight .nav-items .btn {
  margin-left: 15px;
}

body .navbar-hight .items li a:hover {
  color: var(--main);
  cursor: pointer;
}

body .navbar-hight .items li.active::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  min-width: 60px;
  width: 100%;
  height: 5px;
  border-radius: 20px;
  background-color: var(--main);
  top: 100%;
}

body .navbar-hight .items li.active a {
  color: var(--main);
  cursor: pointer;
}


body .navbar-hight .nav-items .logo img {
  max-width: 200px;
}


/* header */

body .HeaderHigh {
  min-height: 700px;
  width: 100%;
  padding-bottom:50px; 
  position: relative; 
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; 
  background-image: url("https://webartdevelopers.com/blog/wp-content/uploads/2022/07/grainy-gradient-blob-with-filters-masking-and-blending.png");
}

body .HeaderHigh.active .watermark {
  display: none !important;
  font-weight: 500 !important;
}

 

body .watermark {
  width: 100%;
  height: 100%; 
  font-size:150px;
  position: absolute; 
  position: absolute;
  top:170px;
  left:15%;
  font-weight: bold;
  text-transform: uppercase;
  z-index:1;
  transform: rotate(-380deg);
  opacity:0.1;
}

body .HeaderHigh .image-wall {
  width: 100%;
  height: 100%; 
}


body .HeaderHigh .imageoverlay {
  width: 100%;
  height: 100%;   
  padding-top: 150px;
  color: var(--black);  
}

 
body .HeaderHigh .imageoverlay .content {
  max-width: 1300px;
  padding: 20px;
  margin:0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

body .HeaderHigh .imageoverlay .content h1 {
  font-weight: 900 !important;
  font-size: 70px;
}


body .HeaderHigh .imageoverlay .content h5 { 
  font-size: 18px;
  font-style: italic;
}


body .HeaderHigh .imageoverlay .content h1 span {
  font-weight: bolder !important;
  font-size: 70px !important;
  color: var(--main);
}

body .HeaderHigh .imageoverlay .content p {
  max-width: 920px;
  color:var(--black);
}

body .HeaderHigh .imageoverlay .content .buttons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

body .HeaderHigh .imageoverlay .content .buttons .btn {
  margin-right: 15px;
  margin-top: 15px;
}

body .HeaderHigh .imageoverlay .content .buttons .btn.bg-black{
   background:var(--black);
}


body .HeaderHigh .btn.black{ 
  background: var(--main);
}

body .HeaderHigh .highlighttext{ 
   font-size:50px;
   font-weight: bolder;
   stroke-width:10px;
}


body .g1::after{
   content:"";
   position: absolute;
   left:0px;
   width:100px;
   height:100px;
   border-radius:100%;
   background-color:var(--main);
   top:0px;
}


body .g1,
body .g1 h1{
  position: relative;
  width: auto !important;
  margin:0px !important;
  height: auto !important;
  display: block !important; 
   font-weight: bolder;
   color: white !important;
   font-size:30px !important;
}

 

/*services section */

body .ServicesHigh {
  padding: 60px 20px;
  background: #EDF2F4;
}

body .ServicesHigh .title {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 40px;
  width: 100%;
}

body .ServicesHigh h1 {
  font-size: 45px;
  max-width: 600px;
  font-weight: 800;
}

body .ServicesHigh .wrap-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

body .ServicesHigh .card-service {
  width: 32.5%;
  overflow: hidden;
  border-radius: 10px; 
  height: 220px;
  margin-bottom: 20px;
  position: relative;
  min-width: 250px;
  background:#ffff;
  box-shadow:0px 3px 10px rgba(189, 189, 189, 0.1);
}

body .ServicesHigh .card-service .image img {
  object-fit: cover;
  width: 100%;
  height: 100% !important;
}

body .ServicesHigh .card-service .image {
  opacity: 0;
  transition: all 1s ease-in;
  height:100% !important;
}

body .ServicesHigh .card-service .dets {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding: 40px 20px;
}

body .ServicesHigh .card-service .space {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

body .ServicesHigh .card-service .space img {
  max-width: 70px;
}

body .ServicesHigh .card-service a {
  color: var(--main);
  font-size: 16px;
}

body .ServicesHigh .card-service .block h5 {
  font-size: 18px !important;
  color: grey;
}

body .ServicesHigh .card-service h3 {
  font-size: 20px;
  font-weight: 600;
  color: #04080F;
}

body .ServicesHigh .card-service:hover .image {
  opacity: 1 !important;
}


body .ServicesHigh .card-service:hover .dets {
  color: #fff;
  background: linear-gradient(to top, var(--black), 60%, rgba(0, 0, 0, 0.1));
}

body .ServicesHigh .card-service:hover .dets a:hover,
body .ServicesHigh .card-service:hover .dets h5 ,
body .ServicesHigh .card-service:hover h3{
  color: silver !important;
}


/*Why us */

body .WhyHigh {
  position: relative;
  min-height: 650px;
}

body .WhyHigh .wall {
  width: 100%;
  height: 100%;
  height: 750px;
}

body .WhyHigh .wall img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

body .WhyHigh .content {
  width: 100%;
  padding: 60px 0px;
  min-height: 650px;
  height: 100%;
  background: linear-gradient(to right, var(--black), 80%, rgba(0, 0, 0, 0.3));
  position: absolute;
  left: 0px;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}


body .WhyHigh .content .info {
  display: flex;
  padding: 20px;
}

body .WhyHigh .content .info .images {
  display: flex;
}

body .WhyHigh .content .info .block {
  display: block;
}

body .WhyHigh .content .info .img {
  width: 350px;
  min-width: 300px;
  height: 250px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 5px solid #d5d5d5;
  overflow: hidden;
}

body .WhyHigh .content .info img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

body .WhyHigh .content .full {
  margin-left: 20px;
  background: white;
  justify-content: center;
  align-items: center;
  display: flex;
 overflow: hidden;
 border-radius: 10px;
 max-height:520px;
 border: 5px solid #d5d5d5;
}

body .WhyHigh .content .full .img {
  width:100%;
  min-width:350px;
  height:400px;
  border: none !important;
  object-fit: contain !important; 
}

body .WhyHigh .content img {
  width: 100% !important;
  height: 100% !important;
}

body .WhyHigh .content .text {
  padding-left: 30px;
  color: #ffff;
  padding-top: 20px;
}

body .WhyHigh .content .text h1 {
  font-size: 45px;
  max-width: 600px;
  font-weight: 800;
}

body .WhyHigh .content .text ul li {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
  font-size: 16px !important;
  border-bottom: 2px solid #ffff;
}


body .WhyHigh .content .text ul li h4 {
  font-size: 18px;
  margin: 0px;
  margin-left: 20px;
}

body .WhyHigh .content .text ul li svg {
  font-size: 40px;
  color: var(--main);
}


/** Latest works */

body .LatestWorks {
  padding: 40px 20px;
}

body .LatestWorks h1 {
  font-size: 45px;
  font-weight: 800;
}

body .LatestWorks p {
  max-width: 900px;
  color: grey;
}


/* Excelency  */

body .ExcelencyHigh {
  padding: 40px 20px;
  background: #EDF2F4;
}

body .ExcelencyHigh .content .text {
  padding-right: 30px;
}



body .ExcelencyHigh .content {
  width: 100%;
  display: flex;
  align-items: center;
}

body .ExcelencyHigh h1 {
  font-size: 45px;
  font-weight: 800;
}

body .ExcelencyHigh p {
  color: grey;
}

body .ExcelencyHigh .content .image img {
  max-width: 600px;
}

body .ExcelencyHigh .content .text p {
  max-width: 900px;
}

/*Latest products **/

body .LatestProducts {
  background-color: var(--black);
  color: #ffff;
}

body .LatestProducts .content {
  padding: 60px 20px;
}


body .LatestProducts h1 {
  font-size: 45px;
  font-weight: 800;
}

body .LatestProducts p {
  max-width: 900px;
}


body .LatestProducts .slider-container {
  margin: 30px 0px;
}


body .ProductCard {
  margin-right: 25px;
  display: flex;
  flex-direction: column-reverse;
}


body .ProductCard .image {
  height: 300px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px 0px;
}

body .ProductCard img {
  width: 100%;
  height: 100%;
  transition: all 1s ease-in-out;
}

body .ProductCard img:hover {
  transform: scale(1.2);
}

body .ProductCard a,
body .ProductCard .dets,
body .ProductCard span,
body .ProductCard h3,
body .ProductCard h2 {
  color: #ffff;
  margin: 5px 0px;
}

body .ProductCard span {
  color: var(--main);
  text-transform: uppercase;
}

body .ProductCard h3 {
  font-size: 20px;
}

body .ProductCard h5 {
  font-size: 16px !important;
  margin-top: 10px;
}

/*Brands */


body .BrandsSections .brd {
  padding: 20px 0px;
}

body .BrandsSections .brd {
  margin: 20px;
}

body .BrandsSections img {
  max-width: 180px;
  max-height: 60px;
}

body .BrandsSections .brd.lg img {
   transform: scale(1.6);
}

body .BrandsSections img {
  max-width: 180px;
  max-height: 60px;
}

/*Newsletter  **/


body .NewsLetterSection .content {
  height: 350px;
  width: 100%;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}
body .NewsLetterSection .wall ,
body .NewsLetterSection .wall img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

body .NewsLetterSection .formarea {
  position: absolute;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, var(--black), 70%, rgba(0, 0, 0, 0.1));
}

body .NewsLetterSection form {
  width: 100%;
  display: flex;
  background: #ffff;
  border-radius: 10px;
  max-width: 900px;
}

body .NewsLetterSection h1 {
  color: #fff;
  margin: 0px;
  margin-bottom: 20px;
  font-size: 45px;
  font-weight: 800;
  max-width: 800px;
}

body .NewsLetterSection input {
  border: none;
}

body .NewsLetterSection {}

/*  Footer */


body .FooterHight {
  background: var(--black);
  color: #ffff;
  margin-top: 150px;
  padding: 20px;
}

body .FooterHight .content {
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
}

body .FooterHight .footer-info .wrapper {
  max-width: 1000px;
}

body .FooterHight .footer-info .box {
  color: #ffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  border-radius: 20px;
  margin-top: -80px;
  background-color:var(--main);
  width: 100%;
  z-index: 10;
  position: relative;
  align-self: center;
}

body .FooterHight .footer-info .box .bx {
  display: flex;
  margin: 10px;
}

body .FooterHight .footer-info .box .bx p {
  font-size: 15px;
  margin: 5px 0px;
  max-width: 300px;
}

body .FooterHight .footer-info .box .bx .icon {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffff;
}

body .FooterHight .footer-info .box .icon {
  color: var(--main);
  font-size: 30px;
}

body .FooterHight .footer-info .box .bx svg {
  color: var(--black);
}

body .FooterHight .content .text img {
  max-width: 300px;
  margin-bottom: 20px;
}

body .FooterHight .content .text {
  max-width: 600px;
}

body .FooterHight .content .links {
  display: flex;
  justify-content: space-between;
}

body .FooterHight .content .links section {
  margin: 20px;
}

body .FooterHight .content .links section h1 {
  font-size: 20px;
  margin-bottom: 20px;
}

body .FooterHight .content .links section li {
  margin: 10px 0px;
}

body .FooterHight .content .links section a {
  color: silver;
  transition: all 1s ease-in-out;
}

body .FooterHight .copyright {
  border-top: 1px solid rgb(34, 34, 34);
  padding-top: 20px;
}


body .FooterHight .copyright {
  width: 100%;
}


body .FooterHight .space {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

body .FooterHight .flex {
  display: flex;
  align-items: center;
}


body .FooterHight .icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: var(--main);
  margin-left: 15px;
  color: #ffff;
}


body .FooterHight .copyright .flex {
  font-size: 16px;
  color: silver;
}



body .FooterHight li a:hover {
  color: var(--main) !important;
}






/* contacts page  **/


body .HeaderContact {
  min-height: 550px;
  padding-top: 200px;
  width: 100%;
  background-color: var(--main);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; 
  background-image: url("https://webartdevelopers.com/blog/wp-content/uploads/2022/07/grainy-gradient-blob-with-filters-masking-and-blending.png");
}

body .HeaderContact .content {
  width: 100%; 
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}


body .ct-box {
  width: 100%; 
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; 
}


body .HeaderContact .content h1 {
  font-weight: 900 !important;
  font-size: 70px;
  color:var(--black);
}

body .HeaderContact .content h1 span {
  font-size: 70px;
  font-weight: bolder; 
}

body .HeaderContact p {
  max-width: 900px;
  color:var(--black);
}



body .Mapcomponent,
body .Mapcomponent div,
body .Mapcomponent iframe {
  width: 100% !important;
  height: 450px !important;
  margin-bottom: -150px;
}

body .FormBox .content {
  display: flex;
  align-items: center;
  justify-content: center; 
}



body  .info-area{
  background-color: #ffff;
  border-radius: 10px;
  width: 100%;
  max-width: 1000px;
  min-height:500px;
  padding: 20px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  margin-top:-150px;
}

body  .info-area .mapb{
   min-width:400px;
   height:450px;
}

body  .info-area .mapb iframe{
  min-width:400px;
  height:450px;
}

body  .info-area  ul{
  padding:0px; 
  padding-right:20px;
  padding-top:0px;
  min-height:90%; 
  display: flex;
  align-items: center; 
  flex-direction: column;
}

body  .info-area  ul li{
  width:100%;
  padding:10px 15px;
  margin:15px 0px;
  border-radius:5px;
  display: flex;
  align-items: center;
  background-color: rgb(236, 236, 236);
}

body  .info-area  ul li .icon{
  margin-right:20px;
}


body  .info-area  ul li .icon svg{
   width:25px;
   height:25px;
}



body .FormBox .form-box {
  display: none;
  background-color: #ffff;
  border-radius: 10px;
  width: 100%;
  max-width: 1000px;
  padding: 20px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}


body .FormBox .form-box input,
body .FormBox .form-box textarea {
  background: rgb(238, 238, 238);
  border: none !important;
}

body .FormBox .flex-box {
  display: flex;
}

body .FormBox .flex-box .f {
  width: 100%;
}

body .FormBox .pd {
  padding: 10px;
}

body .FormBox .form-box .btn:hover {
  border-color: 1px solid var(--main) !important;
}

body  .info-area h1,
body .FormBox h1 {
  font-size: 25px;
  font-weight: 900;
  margin-bottom: 20px;
}

/* about  */

body .about-area {
  min-height: 400px;
  padding: 100px 20px;
}

body .about-area .box {
  display: flex;
  align-items: center;
}

body .about-area .box .text {
  background: var(--main);
  min-height: 300px;
  padding: 20px;
  width: 100%;
  color: #ffff;
  max-width: 600px;
  border-radius: 10px;
  z-index: 100;
}

body .about-area .box .image {
  margin-left: -100px;
  width: 100%;
}

body .about-area .box .image img {
  width: 100%;
  border-radius: 10px;
  max-height: 600px;
  object-fit: cover;
}

body .about-area .box .text h1 {
  font-size: 45px;
  max-width: 600px;
  font-weight: 800;
  margin-bottom: 20px;
}


body .about-area .box .text p {
  font-size: 16px;
  color:var(--black); 
}

body .about-area .block-data {
  display: flex;
  align-items: center;
  margin: 60px 0px;
}

body .about-area .block-data.rev {
  flex-direction: row-reverse !important;
}


body .about-area .block-data h1 {
  font-size: 45px;
  max-width: 600px;
  font-weight: 800;
  margin-bottom: 20px;
}

body .about-area .block-data aside {
  max-width: 750px;
  padding-right: 50px;
}


body .about-area .block-data.rev aside {
  padding-left: 50px;
  padding-right: 0px;
  flex-direction: row-reverse !important;
}

body .about-area .block-data .image {
  width: 100%;
  height: 100%;
  min-width: 700px;
}

body .about-area .block-data img {
  width: 100%;
  height: 100%;
  max-height: 450px;
  border-radius: 10px;
  object-fit: cover;
}


body .mb0 {
  margin-bottom: 0px !important;
}

body .About .FooterHight {
  margin-top: 0px !important;
}

body .FormBox {}

body .FormBox {}


body .responsive-menu,
body .toggle {
  display: none;
}



/*Services page */

body .services-area {
  min-height: 400px;
}

body .services-area .Header {
  background-color: var(--black);
  color: #ffff;
  padding: 60px 20px;
  padding-top: 140px;
  max-height: 650px;
}

body .services-area img {
  width: 100%;
  border-radius: 20px;
}

.HeaderServices {
  padding-top: 130px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; 
  background-image: url("https://webartdevelopers.com/blog/wp-content/uploads/2022/07/grainy-gradient-blob-with-filters-masking-and-blending.png");
  max-height: 650px;
  margin-bottom: 300px;
}

body .HeaderServices .service-header-area {
  display: flex;
  padding: 0px 20px;
  justify-content: space-between;
}

body .HeaderServices .service-header-area section {
  max-width: 750px;
  display: block; 
  padding: 20px;
  min-width: 500px;
}

body .HeaderServices .service-header-area .image {
  width: 100%;
}

body .HeaderServices .service-header-area img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  max-height: 500px;
  min-height:450px;
  border-radius: 6px;
}


body .HeaderServices .service-header-area p,
body .HeaderServices .service-header-area h1 {
  margin-bottom: 70px;
}

body .HeaderServices .service-header-area p {
  color: var(--black);
}



body .WhatWeDoComponent .block-text h1 {
  font-size:45px;
  max-width: 600px; 
  font-weight: 800;
  color:var(--black);
}

body .HeaderServices .service-header-area h1 {
  font-size: 70px ; 
  font-weight: 800;
  color:var(--black);
}

body .WhatWeDoComponent {
  background: var(--main);
  color: #ffff;
  padding: 80px 0px;
  display: flex;
  justify-content: space-between;
}



body .WhatWeDoComponent .block-text {
  display: flex;
  padding: 0px 20px;
}


body .WhatWeDoComponent .block-text .info {
  padding-right: 40px;
  max-width: 600px;
  min-width: 600px;
}

body .WhatWeDoComponent .block-text h1 {
  color: #ffff;
  margin-bottom: 40px;
}

body .WhatWeDoComponent .block-text h2 {
  font-size: 25px;
  margin: 10px 0px;
  font-weight: 600;
  display: flex;
  color: var(--black);
}

body .WhatWeDoComponent .block-text article {
  margin: 25px 0px;
}


body .WhatWeDoComponent .block-text .dt {
  width: 25px;
  min-width:25px;
  height: 25px;
  background-color: #ffff;
  margin-right: 10px;
  border: 7px solid var(--black);
  border-radius: 100%;

}


body .Services .FooterHight {
  margin-top: 100px !important;
}


/*Service details **/


body .service-details{
     padding:140px 20px;
     padding-bottom:0px;
}

body .service-details img{
    margin:0 auto; 
    width:100%;
    object-fit: cover;
    height:400px;
}


body .service-details .box{
      padding:20px;
      font-size:15px;
      font-weight:500 !important;
      border-left:10px solid var(--main);
      border-radius:10px;
      background-color:#E3F2FD;
      color:var(--main);
      margin:20px 0px; 
}

body  .service-information h1,
body .service-details h1{
  font-size: 45px; 
  font-weight: 800;
  margin-top:10px;
  max-width:900px;
}


body .service-details p{
   padding-top:20px;
}

body  .service-information img{
  margin:20px 0px !important;
  width:100%;
}

body  .service-information {
  padding:20px;
  padding-top:30px;
}


body  .service-information {
  
}

body  .service-information {
  
}


/*Not founded page */

body  .notfounded{
   padding:20px;
   min-height:100vh;
   width:100%;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   text-align: center;
   background-color: var(--black);
}


body  .notfounded h2{
   font-size:70px !important;
   margin:20px;
   color:var(--main);
}

body  .notfounded p{
  font-size:20px;
  color:#ffff;
  margin-top:20px;
}



/* Documentation */

body .documentation h1{
  font-size: 45px; 
  font-weight: 800;
}


body .documentation .docs-container{
   padding:0px 20px;
   margin-top:40px;
}




/*Mobile responsive **/
@media screen and (max-width:900px) {
  /*navbar */


body .BrandsSections .brd {
  padding: 20px 0px; 
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

body .BrandsSections .brd {
  margin: 0px !important; 
}

body .BrandsSections img {
  max-width: 230px; 
  margin:0px auto !important; 
  max-height: 90px !important;
}


  body .it {
    display: none !important;
  }
 
  body .BrandsSections .brd.lg img {
    transform: scale(1.4) !important;
 }

 body .links section {
   margin:20px 0px !important;
}

  body .toggle {
    display: flex !important;
    cursor: pointer;
  }

  body .toggle svg {
    width: 40px;
    height: 40px;
    color: #fff;
  }

  body .responsive-menu.active {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
    position: fixed;
    top: 0px;
    left: 0px;
    background: var(--black);
    height: 100vh;
    width: 100%;
    padding: 20px;
  }

  body .responsive-menu .items {
    display: flex;
    width: 100%;
    flex-direction: column !important;
    margin-top: 20px;
  }

  body .responsive-menu button {
    width: 100%;
    margin: 10px 0px;
  }

  body .responsive-menu .items li {
    margin: 10px 0px;
  }

  /*Header **/
  body .HeaderHigh .content {
    padding-top: 0px !important;
  }

  body .HeaderHigh img {
    object-fit: cover !important;
  }

  body .HeaderHigh .imageoverlay {
    padding-top: 130px !important;
  }


  body .HeaderHigh .content h1,
  body .HeaderHigh .wrapper .content h1 span {
    font-size: 35px !important;
  }


  body  .info-area,
  body  .info-area  .mapb,
  body  .info-area  .mapb iframe,
  body .WhatWeDoComponent .block-text,
  body .HeaderHigh .wrapper .content .buttons button,
  body .HeaderHigh .wrapper .content .buttons a {
    width: 100%;
    min-width:100% !important;
    flex-direction: column; 
  }

  body  .info-area ul{
     padding:10px 0px !important;
 }


 body  .info-area {
   margin:0px !important;
   box-shadow: unset !important;
}


 body .HeaderHigh .wrapper  .buttons button {
     text-align: center !important;
     display: flex;
     align-items: center;
     justify-content: center;
  }

  body .WhatWeDoComponent .block-text .dt {
    width: 70px;
    margin-bottom: 10px;
    border-radius: 6px;
    text-align: center;
  }



  body .WhatWeDoComponent .block-text h2 {
    display: unset !important;
  }

  body .HeaderHigh .wrapper .content .buttons button {
    width: 100%;
    text-align: center;
  }

  /*Services section **/

  body .ServicesHigh h1 {
    font-size: 35px;
  }

  body .ServicesHigh .card-service {
    width: 100% !important;
    margin: 10px 0px !important;
  }

  /***Why section */

  body .WhyHigh .wall {
    min-height: 1520px;
  }

  body .service-details h1,
  body  .service-information h1,
  body .WhatWeDoComponent .block-text h1,
  body .WhyHigh h1 {
    font-size: 35px !important;
  }

  body .WhyHigh p {
    margin-bottom: 20px;
  }

  body  .service-information img {
    min-height:300px;
    object-fit: cover;
  }


  body .WhyHigh .images {
    flex-direction: column;
    margin: 0px !important;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px !important;
    padding: 0px 20px !important;
  }

  body .WhyHigh .full,
  body .WhyHigh .full .img {
    min-width: 100% !important;
    width: 100% !important;
    margin: 0px !important;
    padding: 0px !important;
  }

  body .WhyHigh .img {
    min-width: 100% !important;
    width: 100% !important;
    max-height: 250px;
    padding: 0px !important;
  }

  body .WhyHigh .info {
    flex-direction: column;
    padding: 0px 10px !important;
    margin: 0px !important;
  }

  /*Latest works And excelency **/

  body .LatestWorks h1 {
    font-size: 35px !important;
  }

  body .NewsLetterSection h1,
  body .LatestProducts h1,
  body .ExcelencyHigh h1 {
    font-size: 35px !important;
  }


  body .ExcelencyHigh .content {
    flex-direction: column;
  }

  body .ExcelencyHigh button {
    width: 100%;
  }

  body .ExcelencyHigh .image {
    width: 100%;
    min-width: 100% !important;
    padding: 0px !important;
    margin: 0px !important;
  }

  body .ExcelencyHigh .text {
    width:100%;
    padding-right: 0px !important;
  }


  body .ExcelencyHigh img {
    min-width: 100%;
    margin: 0px;
    width: 100% !important;
    margin-top: 20px;
  }



  body .NewsLetterSection .content {
    border-radius: 0px !important;
    min-height: 400px;
  }

  body .NewsLetterSection .content .wall,
  body .NewsLetterSection .content img {
    height: 100%;
    width: 100%;
  }

  /*Footer **/

  .FooterHight .footer-info .box {
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    width: 100%;
    margin-top: -140px !important;
    padding: 20px !important;
  }

  .FooterHight .footer-info .box .bx {
    margin: 10px 0px !important;
  }


  .FooterHight .footer-info .box .bx .icon {
    min-width: 50px !important;
  }


  .FooterHight .content .links,
  .FooterHight .content {
    flex-direction: column;
  }


  .FooterHight .links section {
    margin: 20px 0px;
    padding: 0px !important;
  }

  /*About and contacts */

  .About .box {
    flex-direction: column;
  }

  .About .box .text {
    margin: 0px !important;
    padding: 20px;
    margin-bottom: 20px !important; 
  }

  .HeaderContact h1,
  body .documentation .docs-container h1,
  body .HeaderServices section h1,
  .HeaderContact h1 span,
  .About .block-data h1,
  .About .box h1 {
    font-size: 35px !important;
  }

  .About .box .image {
    margin: 0px !important;
  }

  .About .wrapper .block-data.rev,
  .About .wrapper .block-data {
    flex-direction: column !important;
  }

  .About .block-data aside,
  .About .block-data .image {
    padding: 0px !important;
  }

  .About .wrapper .block-data .image {
    min-width: 100%;
    width: 100%;
  }

  .HeaderContact .content {
    padding: 0px 20px;
  }


  .HeaderContact {
    padding-top: 130px !important;
    min-height: 400px !important;
  }

  body .FormBox .content {
    box-shadow: unset !important;
    margin: 0px !important;
  }

  body .FormBox .form-box {
    box-shadow: unset !important;
  }

  body .FormBox .form-box button {
    width: 100%;
  }

  body .HeaderHigh {
    height: auto !important;   
  }


  body .HeaderHigh img {
    display: none;
  }

  body .Services .HeaderServices {
    max-height: max-content !important;
    height: auto !important;
    padding-bottom: 30px;
    margin-bottom:0px !important;
  }



  body .Services .HeaderServices img {
    max-height: 300px !important;
    min-height: auto !important;
  }
 


  body .HeaderServices h1,
  body .HeaderServices p {
    margin-bottom: 20px !important;
  }

  body .HeaderServices p {
    margin-top: 20px !important;
  }

  body .WhatWeDoComponent section,
  body .HeaderServices section {
    padding: 0px 10px !important;
    min-width: auto !important;
  }


  body .WhatWeDoComponent .description {
    margin-top: 20px !important;
    color: var(--black);
  }

  body .WhatWeDoComponent .description p{ 
  color: var(--black) !important;
}

  body .HeaderServices .service-header-area {
    flex-direction: column !important;
  }


  body .FooterHight .copyright .space{
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  body .FooterHight .copyright .space .flex{
     margin-bottom:20px;
  }


}


@media screen and (max-width:335px) {

  body .HeaderHigh {
    min-height: 1000px !important;
  }

}



 